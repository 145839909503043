<template>
    <div class="layout">
        <Layout>
            <Sider ref="sider" :width="224" :style="{background:'#fff',height:'100vh'}">
                <a>
                    <img src="../assets/logo.png" alt="logo" height="64"/>
                </a>
                <Menu active-name="home" theme="light" width="auto" :open-names="['sms', 'app', 'setting']" @on-select="clickMenuItem">
                    <!-- 首页 -->
                    <MenuItem name="home">
                        <Icon type="ios-stats-outline" />
                        首页
                    </MenuItem>
                    <!-- 短信服务 -->
                    <Submenu name="sms">
                        <template slot="title">
                            <Icon type="ios-mail-outline" />
                            短信服务
                        </template>
                        <MenuItem name="sms-batch_send">
                            <Icon type="ios-send-outline" />
                            自助发送
                        </MenuItem>
                        <MenuItem name="sms-ord_record">
                            <Icon type="ios-document-outline" />
                            发送记录
                        </MenuItem>
                    </Submenu>
                    <!-- 数据分析 -->
                    <MenuItem name="analysis">
                        <Icon type="ios-analytics-outline" />
                        数据分析
                    </MenuItem>
                    <!-- 充值服务 -->
                    <MenuItem name="recharge">
                        <Icon type="ios-card-outline" />
                        充值服务
                    </MenuItem>
                    <!-- 我的应用 -->
                    <Submenu name="app">
                        <template slot="title">
                            <Icon type="ios-apps-outline" />
                            我的应用
                        </template>
                        <MenuItem name="app-api_conf">
                            <Icon type="ios-glasses-outline" />
                            API配置
                        </MenuItem>
                        <MenuItem name="app-guide">
                            <Icon type="ios-help-circle-outline" />
                            接入指引
                        </MenuItem>
                    </Submenu>
                    <!-- 服务设置 -->
                    <Submenu name="setting">
                        <template slot="title">
                            <Icon type="ios-settings-outline" />
                            服务设置
                        </template>
                        <MenuItem name="setting-sys_conf">
                            <Icon type="ios-grid-outline" />
                            系统设置
                        </MenuItem>
                        <MenuItem name="setting-user_conf">
                            <Icon type="ios-contact-outline" />
                            账号设置
                        </MenuItem>
                    </Submenu>
                </Menu>
            </Sider>
            <Layout :style="{minHeight: '99vh'}">
                <Header class="header">
                    <div class="slogan">
                        「E快讯」提供更快速更专业的全球通讯云服务
                    </div>
                    <div style="display:flex;align-items: center;">
                        <Tooltip :content="isFullScreen ? '取消全屏':'全屏'" placement="bottom" style="marginTop:8px;">
                            <Icon @click="toggleScreenSize" class="btn-icon" :type="isFullScreen ? 'md-contract':'md-expand'" size="24" color="#606266"/>
                        </Tooltip>
                        <Dropdown @on-click="logout" style="marginLeft:24px;">
                            <a style="color: #606266">
                                <Avatar size="28" icon="ios-person" />
                                <span style="font-size: 16px;"> {{uname}} </span>
                                <Icon type="md-arrow-dropdown" size="16"/>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem>
                                    账户余额￥: {{$store.state.uinfo.balance}}
                                </DropdownItem>
                                <DropdownItem divided>
                                    <Icon type="md-exit" size="16"/> 安全退出
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Header>
                <Content :style="{marginTop:'6px',padding:'15px',minHeight:'280px',minWidth:'900px',background:'#f1f4f5'}">
                    <!-- <keep-alive :exclude="/^(Home|DyRoom)$/" > -->
                    <keep-alive :exclude="['Home', 'OrdRecord', 'DataAnalysis', 'SysConf', 'UserConf']" >
                        <router-view/>
                    </keep-alive>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>
export default {
    name: 'Main',
    data () {
        return {
            isFullScreen: false,
            uname: '-'
        }
    },
    mounted() {
        if (!this.$store.state.hasLogin) {
            this.$Message.error('请登录');
            this.$router.replace('/login');
        } else {
            this.uname = this.$store.state.uinfo.nickname;
        }
    },
    methods: {
        clickMenuItem(name) {
            this.$router.push('/' + name.replace('-', '/'));
        },
        logout() {
            localStorage.removeItem('token');
            this.$store.commit('logout');
            this.$router.replace('/login');
        },
        toggleScreenSize() {
            if (this.isFullScreen) {
                this.exitFullScreen();
            } else {
                this.fullScreen();
            }
        },
        fullScreen() {
            const el = document.documentElement;
            const rfs = el.requestFullScreen || el.webkitRequestFullScreen || 
                el.mozRequestFullScreen || el.msRequestFullScreen;
            if(rfs) {
                rfs.call(el);
            } else if(window.ActiveXObject) {
                //for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                const wscript = new ActiveXObject("WScript.Shell");
                if(wscript) {
                    wscript.SendKeys("{F11}");
                }
            } else {
                return;
            }
            this.isFullScreen = true;
        },
        exitFullScreen() {
            const el = document;
            const cfs = el.cancelFullScreen || el.webkitCancelFullScreen || 
                el.mozCancelFullScreen || el.exitFullScreen;
            if(cfs) {
                cfs.call(el);
            } else if(window.ActiveXObject) {
                //for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                const wscript = new ActiveXObject("WScript.Shell");
                if(wscript) {
                    wscript.SendKeys("{F11}");
                }
            } else {
                return;
            }
            this.isFullScreen = false;
        }
    }
}
</script>
<style scoped lang="scss">
    /* 手机 */
    @media screen and (max-width: 768px) {
        .layout { display: inline-block; }
    }
    .layout {
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .btn-icon:hover {
        cursor: pointer;
    }
    .header {
        min-width: 900px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #17233d;
        background-color: #fff;
        box-shadow: 0 2px 3px rgba(0,0,0,.1);
        .slogan {
            flex: 1;
            text-align: center;
            font-size: 18px;
            color: #606266;
        }
    }
    .view-title {
        font-size:20px;
        height:32px;
        line-height:32px;
        color: #17233d;
    }
</style>