import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
     {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
      },
      {
        path: '/',
        redirect: '/home',
        name: '_home',
        component: Main,
        children: [
          {
            path: '/home',
            name: 'home',
            component: () => import('../views/Home.vue')
          }
        ]
    },
    {
        path: '/sms',
        name: 'sms',
        component: Main,
        children: [
          {
            path: 'batch_send',
            name: 'batch_send',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/sms/BatchSend.vue')
          },
          {
            path: 'ord_record',
            name: 'ord_record',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/sms/OrdRecord.vue')
          }
        ]
    },
    {
        path: '/analysis',
        name: 'analysis',
        component: Main,
        children: [
          {
            path: '/',
            name: 'analysis_index',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/DataAnalysis.vue')
          }
        ]
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: Main,
        children: [
          {
            path: '/',
            name: 'recharge_index',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/Recharge.vue')
          }
        ]
    },
    {
        path: '/app',
        name: 'app',
        component: Main,
        children: [
          {
            path: 'api_conf',
            name: 'api_conf',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/app/ApiConf.vue')
          },
          {
            path: 'guide',
            name: 'guide',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/app/Guide.vue')
          }
        ]
    },
    {
        path: '/setting',
        name: 'setting',
        component: Main,
        children: [
          {
            path: 'sys_conf',
            name: 'sys_conf',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/setting/SysConf.vue')
          },
          {
            path: 'user_conf',
            name: 'user_conf',
            component: () => import(/* webpackChunkName: "favorites" */ '../views/setting/UserConf.vue')
          }
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
