import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasLogin: false,
    uinfo: {},
    token: ''
  },
  mutations: {
    login(state, data) {
        state.hasLogin = true;
        state.uinfo = data.uinfo;
        state.token = data.token;
    },
    logout(state) {
        state.hasLogin = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
